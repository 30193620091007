

















































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import DefineYourGoalsCardViewModel from
  '@/vue-app/view-models/components/goals-dashboard/define-your-goals-card-view-model';

@Component({
  name: 'DefineYourGoalsCard',
  components: {
    LoadingDataCard,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class DefineYourGoalsCard extends Vue {
  view_model = Vue.observable(new DefineYourGoalsCardViewModel());
}
